import React, { useState } from 'react';
import { ethers } from 'ethers';
import erc20ABI from '../Contracts/erc20ABI.json';
import contractABI from '../Contracts/contractABI.json';

const Test = () => {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [erc20Contract, setErc20Contract] = useState(null);
  const [contract, setContract] = useState(null);
  const [amount, setAmount] = useState('');
  
  const erc20Address = '0x62ac64f46c593F7dFb70424Fe228b8AD81324725';
  const contractAddress = '0x64a142F5200121DC7bb5594A53556b82489bE90a';
  
  const connectMetaMask = async () => {
      if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          setProvider(provider);
          setSigner(signer);
          setErc20Contract(new ethers.Contract(erc20Address, erc20ABI, signer));
          setContract(new ethers.Contract(contractAddress, contractABI, signer));
          console.log('MetaMask connected');
      } else {
          console.log('MetaMask not found');
      }
  };
  
  const approveTokens = async () => {
      const amountValue = parseFloat(amount);
      if (isNaN(amountValue) || amountValue <= 0) {
          alert('Please enter a valid amount');
          return;
      }
      try {
          const decimals = await erc20Contract.decimals();
          const amountInDecimals = ethers.utils.parseUnits(amount, decimals);
          const tx = await erc20Contract.approve(contractAddress, amountInDecimals);
          await tx.wait();
          console.log('Approval successful');
      } catch (error) {
          console.error('Approval failed', error);
      }
  };
  
  const loadBalance = async () => {
      const amountValue = parseFloat(amount);
      if (isNaN(amountValue) || amountValue <= 0) {
          alert('Please enter a valid amount');
          return;
      }
      try {
          const tx = await contract.loadBalance(amountValue, erc20Address);
          await tx.wait();
          console.log('Balance loaded successfully');
      } catch (error) {
          console.error('Load balance failed', error);
      }
  };

    return (
        <div>
            <button onClick={connectMetaMask} id="connectButton">Connect MetaMask</button>
            <input 
                type="number" 
                value={amount} 
                onChange={(e) => setAmount(e.target.value)} 
                id="amount" 
                placeholder="Amount" 
            />
            <button onClick={approveTokens} id="approveButton">Approve Tokens</button>
            <button onClick={loadBalance} id="loadButton">Load Balance</button>
        </div>
    );
};

export default Test;
