import React, { useState } from 'react';
import './GPURecommendation.css';

export default function GPURecommendation() {
  const [gpuQuery, setGpuQuery] = useState('');
  const [gpuRecommendation, setGpuRecommendation] = useState('');
  const [showRecommendation, setShowRecommendation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleGpuRecommendation = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://rlayer.clusterprotocol.io/recommend-gpu', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uquery: gpuQuery }),
      });
      const data = await response.json();
      console.log(data);
      if (data.recommendation) {
        setGpuRecommendation(`\t\tIdeal: ${data.recommendation.ideal}\t\tHigh-cost: ${data.recommendation.high_cost}\t\tLow-cost: ${data.recommendation.low_cost}`.trim());
      } else if (data.error) {
        setGpuRecommendation(`Error: ${data.error}`);
      } else {
        setGpuRecommendation('Unexpected response format. Please try again.');
      }
      setShowRecommendation(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching GPU recommendation:', error);
      setGpuRecommendation('Failed to get recommendation. Please try again.');
      setIsLoading(false);
      setShowRecommendation(true);
    }
  };

  const handleRefresh = () => {
    setGpuQuery('');
    setGpuRecommendation('');
    setShowRecommendation(false);
  };

  return (
    <div className="GPU_recommendation_container">
      <h1>GPU Recommendation</h1>
      <div className="GPU_recommendation_content">
        <p>Enter your query about what you want to achieve or your goal. It is recommended to enter as many details as possible to get the best recommendation.</p>
        <p>Examples:</p>
        <ol>
          <li>I need to train a large language model with 1 billion parameters and I have a moderate budget</li>
          <li>I want to render 3D animations for a short film which is 1 hour long.</li>
          <li>I'm building a machine learning model for image classification and I want to train it on a GPU with 16GB of memory.</li>
        </ol>
        <div className="input_section">
          <textarea
            value={gpuQuery}
            onChange={(e) => setGpuQuery(e.target.value)}
            placeholder="Describe your GPU requirements..."
            className="gpu_query_input"
          />
          <div className="button_group">
            <button onClick={handleGpuRecommendation} className="recommend_button" disabled={isLoading}>
              {isLoading ? (
                <img src='./icons/loader.gif' style={{width: '1.5rem', height: '1.5rem', objectFit: 'contain', opacity:'0.4'}} alt="Loading..." />
              ) : (
                'Get Recommendation'
              )}
            </button>
            <button onClick={handleRefresh} className="refresh_button">
              Refresh
            </button>
          </div>
        </div>
        {showRecommendation && (
          <div className="recommendation_section">
            <h2>Recommendation Results</h2>
            <div className="gpu-cards-grid">
              {gpuRecommendation.split('\t\t')
                .sort((a, b) => {
                  const orderMap = { 'Low-cost': 0, 'Ideal': 1, 'High-cost': 2 };
                  const [catA] = a.split(': ');
                  const [catB] = b.split(': ');
                  return orderMap[catA] - orderMap[catB];
                })
                .map((rec, index) => {
                const [category, name] = rec.split(': ');
                const isIdeal = category === 'Ideal';
                return (
                  <div key={index} className={`gpu-card ${isIdeal ? 'ideal-gpu' : ''}`}>
                    <div className="gpu-image">
                      <img 
                        src={`/gpu0images/${name}.png`}
                        className={isIdeal ? 'video-large' : 'video-small'}
                        alt={name}
                      />
                    </div>
                    <div className="gpu-info">
                      <h3 className="gpu-name">{name}</h3>
                      <span className="gpu-category">{category}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}