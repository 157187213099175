
import { BsTwitter, BsTwitterX } from "react-icons/bs";
import { FaDiscord, FaLinkedinIn, FaTelegram } from "react-icons/fa";
import { FaMedium } from "react-icons/fa6";

import { LuActivitySquare, LuHome } from "react-icons/lu";
import { TbHexagonalPrismPlus } from "react-icons/tb";
import { TiTick } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  return (
    <>
    <footer className="mainFooter">
        <div style={{display: 'flex', alignItems: 'center', gap: '2rem'}}>
            <Link to={'/home'}><img style={{ height: '1.6rem' }} src='./assets/clusterProtocol.webp' className='navbarLockerimage' alt="Cluster Protocol" /></Link>
            <div>
                <p style={{ color: 'rgb(200,200,200)', fontFamily: 'poppins', fontWeight: '400', display: 'flex', alignItems: 'center', gap: '0.5rem' }}><span style={{display: 'flex', alignItems: 'center'}}><TiTick size={25} color="green" /></span> All Service Online</p>
            </div>
        </div>
                

        <div style={{display: 'flex', alignItems: 'center', gap: '1.2rem', opacity: '0.6'}}>
          <Link href="https://t.me/clusterprotocolchat" target="_blank">
          <FaTelegram size={20}/>
          </Link>
          <Link href="https://www.linkedin.com/company/clusterprotocol" target="_blank">
          <FaLinkedinIn size={20}/>
          </Link>
          <Link href="https://medium.com/@clusterprotocol.io" target="_blank">
          <FaMedium size={20}/>
          </Link>
          <Link href="https://twitter.com/ClusterProtocol" target="_blank">
          <BsTwitterX size={20}/>
          </Link>
        <p style={{ color: 'rgb(200,200,200)', fontFamily: 'poppins', fontWeight: '400', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>| &copy; Cluster Protocol 2024 </p>

        </div>
    </footer>
    <footer className="navigatorFooter">

          <Link to="/home">
            <h2 className={location.pathname === '/home' ? 'selectedFooter' : ''} style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', fontSize: '1rem' }}><span style={{paddingTop: '0.20rem'}}><LuHome size={20} /></span><span style={location.pathname !== '/home' ? {display: 'none'} : {}}>Home</span></h2>
          </Link>
          <Link to="/newcluster">
            <h2 className={location.pathname === '/newcluster' ? 'selectedFooter' : ''} style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', fontSize: '1rem' }}><span style={{paddingTop: '0.20rem'}}><TbHexagonalPrismPlus size={20} /></span><span style={location.pathname !== '/newcluster' ? {display: 'none'} : {}}>New Cluster</span></h2>
          </Link>
          <Link to="/activeclusters">
            <h2 className={location.pathname === '/activeclusters' ? 'selectedFooter' : ''}style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', fontSize: '1rem' }}><span style={{paddingTop: '0.20rem'}}><LuActivitySquare size={20} /></span><span style={location.pathname !== '/activeclusters' ? {display: 'none'} : {}}>Active Cluster</span></h2>
          </Link>

    </footer>
    </>
  );
};

export default Footer;
