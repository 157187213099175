//  *@ CLUSTER PROTOCOL - GPU MARKETPLACE
//  *GNU General Public License v3.0
//  *Copyright (C) 2024 


// <== IMPORTANT LIBRARIES ==>
import { motion } from 'framer-motion';
import React, { useState,useEffect } from 'react';
import { useAccount } from 'wagmi';
import { AiOutlineCloudDownload, AiOutlineCloudUpload } from 'react-icons/ai';
import { MdOutlineSecurity } from 'react-icons/md';
import { SiJupyter, SiPytorch, SiVisualstudio } from 'react-icons/si';
import { TbWorldBolt } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { BsTerminal } from 'react-icons/bs';
import { CgTerminal } from 'react-icons/cg';
import Loading from '../Loading';
import copy from 'copy-to-clipboard';

function convertTimestampToString(timestamp) {
    const date = new Date(timestamp * 1000);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
}

function calculateRunningTime(startTime, endTime, currentTime) {
    const effectiveEndTime = currentTime > endTime ? endTime : currentTime;
    const runningTimeInSeconds = effectiveEndTime - startTime;
    return runningTimeInSeconds > 0 ? runningTimeInSeconds / 3600 : 0;
}

function calculateRemainingHours(endTime, currentTime) {
    const remainingTimeInSeconds = endTime - currentTime;
    return remainingTimeInSeconds > 0 ? remainingTimeInSeconds / 3600 : 0;
}

function calculateProgressPercentage(startTime, endTime, currentTime) {
    const totalDuration = endTime - startTime;
    const elapsedDuration = Math.min(currentTime - startTime, totalDuration); // Ensure elapsed time does not exceed total duration
    return (elapsedDuration / totalDuration) * 100;
}
export default function AboutCluster() {
    const [dataOrderDetail, setDataOrderDetail] = useState(null);
    const [runningTime, setRunningTime] = useState("0 hr 0 m 0 s");
    const [clusterID, setClusterID] = useState("CLUSTER-ORDER-ID-0000");
    const [computeRemaining, setComputeRemaining] = useState("0 hr");
    const [clusterStart, setClusterStart] = useState("01/03/24 10:56:23");
    const [clusterEnd, setClusterEnd] = useState("01/03/24 12:56:23");
    const [paid, setPaid] = useState("$10.41");
    const [refunded, setRefunded] = useState("$0.00");
    const [downloadSpeed, setDownloadSpeed] = useState("0.4GB/s");
    const [uploadSpeed, setUploadSpeed] = useState("3.1GB/s");
    const [locationPing, setLocationPing] = useState("10.32GB/s");
    const [location, setLocation] = useState("Germany");
    const [gpuIP, setGpuIP] = useState("x2 Units");
    const [gpuType, setGpuType] = useState("GeForce RTX 3070 Ti");  
    const [progressCluster, setProgressCluster] = useState(0);
    const [loading, setloading] = useState(true)
    const [sshUrl, setSshUrl] = useState('');
    const { orderId } = useParams();
    const { address } = useAccount();

    useEffect(() => {
        const fetchOrders = async () => {
            setClusterID("CLUSTER-ORDER-ID-" + orderId);
            if (!address || !orderId) {
                return;
            }

            const responseOrderDetail = await fetch('https://api.clusterprotocol.io/api/machine/getOrderDetails', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${localStorage.getItem('token')}` // Include the JWT as a Bearer token
                },
                body: JSON.stringify({ orderId: orderId })
            });

            const data = await responseOrderDetail.json();
            if (data.renter !== address) {
                return;
            }
            if (data) {
                const response = await fetch('https://api.clusterprotocol.io/api/machine/available',
                    {
                      method: 'GET',
                      headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('token')}` // Include the JWT as a Bearer token
                    }
                    }
                  );
                
                const machinesData = await response.json();
                const gpuData = (machinesData.message.find(machine => machine.machineId === data.machineId));
                setGpuType(gpuData.gpuName);
                setLocation(gpuData.region);
                setGpuIP(gpuData.IPAddress);

                setDataOrderDetail(data);
                setClusterStart(convertTimestampToString(data.orderStartTime));
                setClusterEnd(convertTimestampToString(data.orderEndTime));
                setPaid(data.amountPaid + " $");
                setSshUrl(data.sshCommand);
                const currentTime = Math.floor(Date.now() / 1000);
                setComputeRemaining(calculateRemainingHours(data.orderEndTime, currentTime).toFixed(2) + " hr");
                setRunningTime(calculateRunningTime(data.orderStartTime, data.orderEndTime, currentTime).toFixed(2) + " hr");
                setProgressCluster(calculateProgressPercentage(dataOrderDetail.orderStartTime, dataOrderDetail.orderEndTime, currentTime).toFixed(2));
            }
        };

        fetchOrders();
    }, [address, orderId]);

    useEffect(() => {
        if (dataOrderDetail) {
            setloading(false)

            const interval = setInterval(() => {
                const currentTime = Math.floor(Date.now() / 1000);
                setComputeRemaining(calculateRemainingHours(dataOrderDetail.orderEndTime, currentTime).toFixed(2) + " hr");
                setRunningTime(calculateRunningTime(dataOrderDetail.orderStartTime, dataOrderDetail.orderEndTime, currentTime).toFixed(2) + " hr");
                setProgressCluster(calculateProgressPercentage(dataOrderDetail.orderStartTime, dataOrderDetail.orderEndTime, currentTime).toFixed(2));
            }, 2000);

            return () => clearInterval(interval);
        }
    }, [dataOrderDetail]);


    return (
        <div>
            <motion.div className='headerInfoContainer'
            style={{ transition: '0.3s' }}
            initial={{ translateY: '-5rem' }}
            animate={{ translateY: '0%' }}
            exit={{ opacity: 0 }}
        >
            <h1 style={{margin:'0.3rem 0', fontSize: '1.3rem', color: 'rgb(250,250,250)', }}>Cluster Stats</h1>
            <h3 style={{ color: 'rgb(150,150,150)', fontSize: '1rem',fontWeight: '400',margin:0, fontFamily: 'poppins' }}>
            Connect and see your stats about your Cluster
            </h3>
        </motion.div>
        {loading ? <Loading /> : null}
        <div className='GA_bgD_DIV'>
                <img className='GA_bgD' src='./assets/bg_design.svg' alt="Background Design"></img>
            </div>
        <motion.div style={{ transition: '2s ease' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>


            <div style={{ margin: 'auto', width: '90%', marginTop: '0', paddingTop: '2rem', }}>
                <h2>Elapsed Time : {runningTime}</h2>
                <h3 className='clusterIDContainer'>ClusterID : <span style={{ color: '#29b2f8', fontWeight: '500' }}>{clusterID}</span></h3>
                <h2 style={{ margin: '1rem 0', marginTop: '2rem', fontWeight: '100', color: 'grey', fontSize: '1rem' }}>Compute Remaining: <span style={{ color: '#29b2f8', fontWeight: '500' }}>{computeRemaining}</span></h2>
            </div>

            <div className='AC_progressBarContainer'>
                <div className='AC_progressBar' style={{ width: `${progressCluster}%` }}></div>
            </div>

            <div className='AC_1x4'>
                {[{ title: "Cluster Started", value: clusterStart }, { title: "Cluster End", value: clusterEnd }, { title: "Paid", value: paid }].map((item, index) => (
                    <div key={index} className='AC_1x3_item'>
                        <h1>{item.title}</h1>
                        <h2>{item.value}</h2>
                    </div>
                ))}
            </div>

            <div className='AC_1x3'>
                {[{
                    title: "Connectivity Tier", buttons: [
                        { icon: <AiOutlineCloudDownload size={18} />, label: "Download Speed", value: downloadSpeed },
                        { icon: <AiOutlineCloudUpload size={18} />, label: "Upload Speed", value: uploadSpeed }]
                }, {
                    title: "Cluster Purpose", buttons: [{ icon: <SiPytorch />, label: "Pytorch" }]
                }, {
                    title: "Security Compliance", buttons: [{ icon: <MdOutlineSecurity />, label: "End-to-End-Encrypted" }]
                }].map((item, index) => (
                    <div key={index} className='AC_1x3_item'>
                        <h1>{item.title}</h1>
                        {item.buttons.map((btn, idx) => (
                            <button key={idx}>
                                <span style={{ display: 'flex', justifyContent: 'space-between', width: '90%', margin: 'auto', alignItems: 'center' }}>
                                    <span style={{ display: 'flex', justifyContent: 'flex-start', gap: '1rem' }}>
                                        <span>{btn.icon}</span> {btn.label}
                                    </span>
                                    {btn.value && <h2 style={{ margin: 0, fontSize: '0.8rem' }}>{btn.value}</h2>}
                                </span>
                            </button>
                        ))}
                    </div>
                ))}
            </div>

            <div className='AC_1x3' style={{ marginBottom: '10rem' }}>
                <div className='AC_1x3_item'>
                    <h1>Location Ping</h1>
                    <h2 className='gGreen' style={{ margin: 0 }}>{locationPing}</h2>
                    <button>
                        <span><TbWorldBolt /></span> {location}
                    </button>
                </div>

                <div className='AC_1x3_item'>
                    <h1>Development Environment</h1>
                    <button>
                        <span style={{ display: 'flex', justifyContent: 'space-between', width: '90%', margin: 'auto', alignItems: 'center',cursor: 'pointer' }} onClick={() => copy(sshUrl)}>
                            <span style={{ display: 'flex', justifyContent: 'flex-start', gap: '1rem' }}>
                                <span><CgTerminal size={18} /></span> Connect via SSH
                            </span>
                            <h2 style={{ margin: 0, fontSize: '0.8rem' }}>Copy</h2>
                        </span>
                    </button>
                </div>

                <div className='AC_1x3_item'>
                    <h1>Cluster GPU</h1>
                    <h2 className='gGreen' style={{ margin: 0 }}>{gpuType}</h2>
                    <button >
                        {gpuIP}
                    </button>
                </div>
            </div>

        </motion.div>
        </div>
    );
}
