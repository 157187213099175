export default function Loading() {
    return (
        <div style={{position: 'fixed', top: '0px', left: '0px', width: '100%', height: '100%', display: 'flex', pointerEvents: 'fill', backdropFilter: 'blur(0px)', justifyContent: 'center', alignItems: 'center', backgroundColor: '#00000078', zIndex: '9'}}>
            <div class="loading-items">
                {/* <div class="loading">
                    <span></span>
                    <span></span>
                    <span></span>
                </div> */}
                <img    src='./icons/loader.gif' style={{width: '4rem', height: '4rem', objectFit: 'contain', opacity:'0.4'}}></img>
            </div>
        </div>
    );
}