import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useAccount } from 'wagmi';
import { useState, useEffect } from 'react';
import { LuActivity, LuMapPin } from 'react-icons/lu';
import Loading from '../Loading';

function Modal({ title, content, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        <p>{content}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

function ClusterItem() {
  const { address } = useAccount();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({ isOpen: false, title: '', content: '' });

  const fetchOrders = async () => {
    if (!address) {
      return;
    }

    try {
      const response = await fetch('https://api.clusterprotocol.io/compute/deployment/getOrders', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ userAddress: address })
      });

      const data = await response.json();

      if (!data.success) {
        throw new Error(data.message || 'Failed to fetch orders');
      }

      setOrders(data.orders.filter((order) => order.statusDetails.status === 'Matched'))
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [address]);

  const handleConnectClick = (connectUrl) => {
    console.log("https://"+connectUrl)
    if (connectUrl) {
      window.open("https://"+connectUrl, '_blank');
    }
  };

  const handleFetchLogs = async (deploymentId) => {
    try {
      setLoading(true);
      const response = await fetch(`https://api.clusterprotocol.io/compute/deployment/logs/${deploymentId}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      if (data.success) {
        await fetchOrders();
        if(orders.find(item=> item.deploymentId == deploymentId).statusDetails.status != 'Offline'){
          setModalData({
            isOpen: true,
            title: `Logs for Deployment ${deploymentId}`,
            content: data.events
        });}
      } else {
        alert('Failed to fetch logs.');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching logs:', error);
    }
  };

  const handleFetchEvents = async (deploymentId) => {
    try {
      setLoading(true);
      const response = await fetch(`https://api.clusterprotocol.io/compute/deployment/events/${deploymentId}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      if (data.success) {
        await fetchOrders()
        if(orders.find(item=> item.deploymentId == deploymentId).statusDetails.status != 'Offline'){
          setModalData({
            isOpen: true,
            title: `Events for Deployment ${deploymentId}`,
            content: data.events
          });
      }
      } else {
        alert('Failed to fetch events.');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching events:', error);
    }
  };

  const handleCloseDeployment = async (deploymentId) => {
    try {
      setLoading(true);
      const response = await fetch(`https://api.clusterprotocol.io/compute/deployment/close/${deploymentId}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      if (data.success) {
        alert(`Deployment ${deploymentId} closed successfully.`);
        
        setOrders(orders.filter((order) => order.deploymentId !== deploymentId));
      } else {
        alert('Failed to close deployment.');
      }
    } catch (error) {
      console.error('Error closing deployment:', error);
    }
    setLoading(false);
  };

  const closeModal = () => {
    setModalData({ isOpen: false, title: '', content: '' });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ marginTop: '5rem', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start',overflowY: 'scroll',  gap: '4rem', width: '90%', padding: '1rem 5%' }}>
      {/* Modal for displaying logs or events */}
      {modalData.isOpen && (
        <Modal title={modalData.title} content={modalData.content} onClose={closeModal} />
      )}
      {orders.length === 0 ? (
        <p>No available orders.</p>
      ) : (
        orders.map((order, index) => (
          <motion.div key={index} style={{ position: 'relative', overflow: 'hidden' }} className={order.statusDetails.status === 'Matched' ? "A_AC_item" : "A_AC_item offline"}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.01, delay: index * 0.1 }}>
            
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', filter: 'grayscale(0)' }}>
              <p style={{ opacity: '0.3', margin: '0', marginTop: '1rem' }}><span><LuMapPin /></span> {order.location}</p>
              <p style={{ opacity: '0.3', margin: '0', marginTop: '1rem' }}><span><LuActivity /></span> {order.statusDetails.status === 'Matched' ? 'Online' : 'Offline'}</p>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', filter: 'grayscale(0)' }}>
              <p style={{ fontWeight: 'bold', color: 'white' }}>{order.gpuName}</p>
            </div>

            <img style={{ width: 'auto', height: '121%', position: 'absolute', zIndex: '-1', filter: 'grayscale(1)', right: '-2rem', top: '-1rem', transition: '20s ease', opacity: '0.2' }} src='./icons/nvidia.png' alt="GPU Image" />

            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <button className='A_AC_button2' style={{ opacity: '0.5', cursor: 'not-allowed' }} disabled>
                Manage
              </button>

              <motion.button className='A_AC_button1' onClick={() => handleConnectClick(order.statusDetails.services.url[0])} initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.1, delay: index * 0.5 }}>
                Connect
              </motion.button>

              <motion.button className='A_AC_button1' onClick={() => handleFetchLogs(order.deploymentId)} initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.1, delay: index * 0.5 }}>
                Get Logs
              </motion.button>

              <motion.button className='A_AC_button1' onClick={() => handleFetchEvents(order.deploymentId)} initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.1, delay: index * 0.5 }}>
                Get Events
              </motion.button>

              <motion.button className='A_AC_button1' onClick={() => handleCloseDeployment(order.deploymentId)} initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.1, delay: index * 0.5 }}>
                Close
              </motion.button>
            </div>
          </motion.div>
        ))
      )}
    </div>
  );
}

// Define the ActiveClusters component
export default function ActiveClusters() {
  return (
    <div>
      <motion.div className='headerInfoContainer'
        style={{ transition: '0.3s' }}
        initial={{ translateY: '-5rem' }}
        animate={{ translateY: '0%' }}
        exit={{ opacity: 0 }}>
        <h1 style={{ margin: '0.3rem 0', fontSize: '1.3rem', color: 'rgb(250,250,250)' }}>Your Active Clusters</h1>
        <h3 style={{ color: 'rgb(150,150,150)', fontSize: '1rem', fontWeight: '400', margin: 0, fontFamily: 'poppins' }}>
          Manage and Connect your Active Clusters and past orders.
        </h3>
      </motion.div>

      <motion.div style={{ transition: '2s ease', overflowY: 'scroll', marginBottom: '5rem' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className='GA_bgD_DIV'>
          <img className='GA_bgD' src='./assets/bg_design.svg' alt="Background Design" />
        </div>
        <ClusterItem />
      </motion.div>
    </div>
  );
}
